import React from "react";

function Ico_Plant(props:any){
    return(
        <img style={{width:'50px'}} src="/logoTra.png"/>
    )
}



export default Ico_Plant

