import React from "react";
import Ico_menu from "../asesst/Icons/Ico_menu";
import Ico_Plant from "../asesst/Icons/Ico_plant";
import {fadeinTop } from "../Styles/keyframes";
import Flex from "../Styles/styledComponent/Flex";
import Text from "../Styles/styledComponent/Text";

function Nav(){
    return(
        <Flex  align={"center"} justify={"between"} css={{
            width:"100%",
            paddingTop:"$2",
            "& svg":{
                fill:"$onBg"
            },
            "& svg[id=menu]":{
                display:"none",
                cursor:"pointer",
                fill:"$onBg800",
                "&:hover":{
                    fill:"$onBg",
                }
            },
            "@bp2":{
                "& svg[id=menu]":{
                    display:"block"
                },
            },
            animation:`${fadeinTop} 1s `
        }}>
            
        <Flex align={"center"} css={{
            cursor:"pointer",
            
            
            
        }}>
            <Ico_Plant width="35" height="35"/>
            <Text cursor={"click"} css={{
                headline4_i:"600",
                color:"$primary"
            }}>Watch</Text>
            <Text cursor={"click"} css={{
                headline4_i:"600",
                color:"$secondary"
            }}>Along</Text>
        </Flex>

        <Flex justify={"end"} align="center" css={{
                "@bp2":{
                    display:"none"
                }
        }}>

            <Flex css={{

                marginRight:"$6",
                "@bp2":{
                    marginRight:"$3",
                }

            }}>

                <Text cursor={"click"} css={{

                    color:"$primary",
                    backgroundColor:"$onBg",
                    headline6:"600",
                    padding:"$1 $3",
                    borderRadius:"8px",
                    marginRight: "10px",
                    boxShadow:"$1dp",
                    "&:hover":{
                        color:"$primary",
                        backgroundColor:"$primary200",
                    },
                    "&:active":{
                        boxShadow:"none",
                        color:"$onPrimary",
                        backgroundColor:"$primary600",
                    }
                }}>
                    Download <Text css={{display: "flex", color: "$gblue"}}> Ext <Text css={{color: "$gyellow"}}> ens</Text><Text css={{color: "$ggreen"}}> ion</Text></Text>

                </Text>

                <Text cursor={"click"} css={{

                    color:"$onPrimary",
                    backgroundColor:"$secondary",
                    headline6:"600",
                    padding:"$1 $3",
                    borderRadius:"8px",
                    boxShadow:"$1dp",
                    "&:hover":{
                        color:"$primary",
                        backgroundColor:"$primary200",
                    },
                    "&:active":{
                        boxShadow:"none",
                        color:"$onPrimary",
                        backgroundColor:"$secondary",
                    }
                }}>
                    Go To <Text css={{color: "$onBg"}}>Discord</Text>
                </Text>

            </Flex>



        </Flex>



        </Flex>
    )
}


export default Nav